<template>
  <div>
    <a-modal v-model:visible="visible" title="药品详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal"
      width="70vw" :okButtonProps="{ style: { display: 'none' } }">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-table class="ant-table-striped" :columns="columns" :data-source="data" rowKey="medicineId"
          :scroll="{ y: 500 }" :pagination="false" bordered>
          <template #title>
            <div class="thead">“{{ record.name }}”在“{{ record.projectName }}”治疗中的应用</div>
          </template>
          <!-- <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations">
                <span>
                  <a @click="getInnerStudy(record)">详情</a>
                </span>
              </div>
            </div>
          </template> -->

        </a-table>
      </a-form>

    </a-modal>
  </div>
</template>

<script>
import { StudyDetail } from "/src/api/innerStudy.js";
export default {
  components: {
  },
  data() {
    return {
      visible: this.modelValue,
      columns: [
        {
          title: "日期",
          key: "date",
          dataIndex: "date",
          width: "8%",
          slots: {
            customRender: "date"
          }
        },
        {
          title: "医生",
          key: "doctorName",
          dataIndex: "doctorName",
          width: "7%",
          slots: {
            customRender: "doctorName"
          }
        },
        {
          title: "病人",
          key: "customerName",
          dataIndex: "customerName",
          width: "7%",
          slots: {
            customRender: "customerName"
          }
        },
        {
          title: "疗效",
          key: "treatmentEffect",
          dataIndex: "treatmentEffect",
          width: "7%",
          slots: {
            customRender: "treatmentEffect"
          }
        },
        {
          title: "关联疾病",
          key: "diseases",
          dataIndex: "diseases",
          width: "10%",
          slots: {
            customRender: "diseases"
          }
        },
        {
          title: "关联症候群",
          key: "syndromes",
          dataIndex: "syndromes",
          width: "10%",
          slots: {
            customRender: "syndromes"
          }
        },
        {
          title: "关联脉象",
          key: "pulse",
          dataIndex: "pulse",
          width: "7%",
          slots: {
            customRender: "pulse"
          }
        },
        {
          title: "处方详情",
          key: "recipe",
          dataIndex: "recipe",
          width: "43%",
          slots: {
            customRender: "recipe"
          }
        },

        // {
        //   title: "操作",
        //   dataIndex: "operation",
        //   width: "10%",
        //   slots: {
        //     customRender: "operation"
        //   }
        // }
      ],
      data: [],
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      console.log(this.record);
      let res = await StudyDetail({ category: this.record.category, projectId: this.record.projectId, medicineId: this.record.medicineId });

      this.data = res.data;
      // if (res.data?.records?.length > 0) {
      // } else {
      //   this.data = []
      // }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    async onDelete(id) {
      console.log(this.record);
      let res = await Update({ id, standardDiseaseId: 0 });
      this.detail()
    },


  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}

.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}

.tr>div {
  width: 12.5%;
  padding: 5px 12px;
}
</style>
