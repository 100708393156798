<template>
  <div>
    <div>
      <div class="searchArea">

        <!-- <a-range-picker v-model:value="inputs.patroltime" :placeholder="['应巡开始时间','应巡结束时间']" :locale="locale" /> -->
        <a-select v-model:value="inputs.category" placeholder="类型" allowClear>
          <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
          <a-select-option value="">全部</a-select-option>
          <a-select-option value="疾病">疾病</a-select-option>
          <a-select-option value="症候群">症候群</a-select-option>
        </a-select>

        <a-button type="primary" shape="circle" @click="search('')">
          <template #icon>
            <SearchOutlined />
          </template>
        </a-button>
        <a-button style="margin-left: 10px" @click="resetForm">重置</a-button>

      </div>
      <a-table class="ant-table-striped" :columns="columns" :data-source="data"
        :rowClassName="(record, index) => (index % 2 === 1 ? 'table-striped' : null)" bordered :scroll="{ y: 550 }">
        <template #operation="{ record }">
          <div class="flex_sa">
            <div class="editable-row-operations">
              <span>
                
                <a @click="getInnerStudy(record)">详情</a>
              </span>
            </div>
          </div>
        </template>

        <template v-for="col in ['name']" #[col]="{ text, record }" :key="col">
          <div v-if="record.category == '疾病'">
            <div>{{ record.projectName }}</div>
          </div>
        </template>
        <template v-for="col in ['name2']" #[col]="{ text, record }" :key="col">
          <div v-if="record.category == '症候群'">
            <div>{{ record.projectName }}</div>
          </div>
        </template>
        <template v-for="col in ['averageEffectScore']" #[col]="{ text, record }" :key="col">
          <a-progress type="line" :percent="text * 10" width="55px" :stroke-color="{
            '0%': '#108ee9',
            '100%': '#87d068',
          }">
            <template #format="percent">
              <span>{{ percent / 10 }}</span>
            </template>
          </a-progress>
        </template>

      </a-table>
    </div>

    <getInnerStudy v-model="visible" :record="selectedRow" />
    <!-- <linkDisease v-model="visible3" :record="selectedRow" @reload="search({ current })" /> -->

  </div>
</template>


<style scoped>
.online {
  background: #40a9ff;
  border-color: #40a9ff;
}

.offline {
  background: #979596;
  border-color: #979596;
}

:deep(.ant-table-tbody > tr > td, .ant-table-thead > tr > th) {
  padding: 10px 8px;
}

.table_btn {
  width: 70px;
  display: inline-block;
  text-align: center;
}

.clickable {
  /* background-color: #000; */
  width: 100%;
  height: 100%;
}
</style>


<script>
import {
  SearchOutlined,
  PlusOutlined,
  DownloadOutlined,
  CheckCircleFilled,
  WarningFilled,
  FormOutlined
} from "@ant-design/icons-vue";
import { reactive } from "vue";
import { cloneDeep } from "lodash-es";
import locale from "ant-design-vue/es/date-picker/locale/zh_CN";
import { Search } from "/src/api/innerStudy.js";
import getInnerStudy from '/src/components/innerStudy/getInnerStudy.vue';

export default {
  components: {
    SearchOutlined,
    PlusOutlined,
    DownloadOutlined,
    locale,
    CheckCircleFilled,
    WarningFilled,
    FormOutlined,
    getInnerStudy,
  },
  name: "自学习结果统计",
  data() {
    return {
      modalBtn: "",
      modalIndex: "0",
      visible: false,
      visible2: false,
      active: undefined,
      id: undefined,
      selectedRow: {},
      total: 0,
      current: 1,
      // pageSize: 10,
      inputs: {
        category: "",
      },

      labelCol: {
        span: 6,
      },
      wrapperCol: {
        span: 14,
      },
      columns: [
        {
          title: "疾病",
          key: "name",
          dataIndex: "name",
          width: "12.5%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "症候群",
          key: "name2",
          dataIndex: "name2",
          ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "name2"
          },
        },
        {
          title: "有效处方数",
          width: "12.5%",
          key: "validPrescriptionCount",
          dataIndex: "validPrescriptionCount",
          slots: {
            customRender: "validPrescriptionCount"
          },
          sorter: (a, b) => {
            return a.validPrescriptionCount - b.validPrescriptionCount
          },
        },
        {
          title: "疗效积分",
          key: "effectScore",
          dataIndex: "effectScore",
          // ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "effectScore"
          }
        },
        {
          title: "平均疗效积分",
          key: "averageEffectScore",
          dataIndex: "averageEffectScore",
          ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "averageEffectScore"
          },
          sorter: (a, b) => {
            return a.averageEffectScore - b.averageEffectScore
          },
        },
        {
          title: "医生数",
          key: "doctorCount",
          dataIndex: "doctorCount",
          // ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "doctorCount"
          }
        },
        {
          title: "患者数",
          key: "customerCount",
          dataIndex: "customerCount",
          // ellipsis: true,
          width: "12.5%",
          slots: {
            customRender: "customerCount"
          }
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "12.5%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],

      editableData: reactive({}),
      selectedRows: {}
      // doExcel: doExcel
    };
  },
  created() { },
  mounted() {
    this.search("");
  },
  activated() {
    if (localStorage.getItem("refrashProject") == "true") {
      // this.search(1);
    }
    localStorage.setItem("refrashProject", false);
  },
  methods: {

    getInnerStudy(record) {
      this.visible = true
      this.selectedRow = { ...record }
    },

    resetForm() {
      this.inputs = {
        category: "",
      }
    },
    async search(pagination) {
      let _this = this;
      this.editableData = {}
      let params = {
        category: this.inputs.category,
        pageSize: this.$store.state.tableSize,
      };
      if (pagination == "") {
        params.current = 1
      } else {
        params.current = pagination.current
        this.current = pagination.current;
      }

      let res = await Search(params)
      if (res.errCode == 0) {
        if (pagination == "") {
          // this.$message.success("查询成功", function () { });
          this.current = 1;
        }
        this.total = res.data.total;
        this.data = res.data;
      } else {
        this.$message.error("查询失败", function () { });
      }
    },


  }
};
</script>