<template>
  <div>
    <a-modal v-model:visible="visible" title="统计详情" @ok="handleOk" okText="新建" cancelText="关闭" :afterClose="closeModal"
      width="65vw" :okButtonProps="{ style: { display: 'none' } }">
      <div style="display: flex;">
        <a-form-item label="平均疗效">
          <a-select class="selector" v-model:value="inputs.averageScore" @change="filterData">
            <a-select-option value="全部">全部</a-select-option>
            <a-select-option value="有效">有效</a-select-option>
            <a-select-option value="无效">无效</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="使用比例">
          <a-select class="selector" v-model:value="inputs.usedRatio" @change="filterData">
            <!-- <a-select-option v-for="item in $store.state.areaNameType" :value="item.NAME">{{ item.NAME }}</a-select-option> -->
            <a-select-option value="全部">全部</a-select-option>
            <a-select-option value="有效">有效</a-select-option>
            <a-select-option value="无效">无效</a-select-option>
          </a-select>
        </a-form-item>
      </div>

      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">

        <a-table class="ant-table-striped" :columns="columns" :data-source="filteredData" :scroll="{ y: 500 }"
          :pagination="false" bordered>
          <template #title>
            <div class="thead">“{{ record.projectName }}”的自学习结果</div>
          </template>
          <template v-for="col in ['averageScore']" #[col]="{ text, record }" :key="col">
            <a-progress v-if="record.scoreValid" type="line" :percent="text * 10" width="55px" :stroke-color="{
              '0%': '#108ee9',
              '100%': '#87d068',
            }">
              <template #format="percent">
                <span>{{ percent / 10 }}</span>
              </template>
            </a-progress>
            <a-progress v-else type="line" :percent="text * 10" width="55px" :stroke-color="{
              '0%': 'rgb(255,77,79)',
              '100%': 'rgb(255,150,150)',
            }">
              <template #format="percent">
                <span>{{ percent / 10 }}</span>
              </template>
            </a-progress>
          </template>
          <template v-for="col in ['usedRatio']" #[col]="{ text, record }" :key="col">
            <a-progress v-if="record.countValid" type="line" :percent="getNumbers(text)" width="55px" :stroke-color="{
              '0%': '#108ee9',
              '100%': '#87d068',
            }">
              <template #format="percent">
                <span>{{ percent }}%</span>
              </template>
            </a-progress>
            <a-progress v-else type="line" :percent="getNumbers(text)" width="55px" :stroke-color="{
              '0%': 'rgb(255,77,79)',
              '100%': 'rgb(255,150,150)',
            }">
              <template #format="percent">
                <span>{{ percent }}%</span>
              </template>
            </a-progress>
          </template>
          <template #operation="{ record }">
            <div class="flex_sa">
              <div class="editable-row-operations">
                <span>
                  <a @click="getInnerStudyDetail(record)">详情</a>
                </span>
              </div>
            </div>
          </template>

        </a-table>
      </a-form>

    </a-modal>
  </div>
  <getInnerStudyDetail v-model="visible2" :record="selectedRow" />
</template>

<script>
import { Detail } from "/src/api/innerStudy.js";
import getInnerStudyDetail from './getInnerStudyDetail.vue';

export default {
  components: {
    getInnerStudyDetail
  },
  data() {
    return {
      visible: this.modelValue,
      visible2: false,
      inputs: {
        averageScore: "全部",
        usedRatio: "全部",
      },
      columns: [
        {
          title: "药品",
          key: "name",
          dataIndex: "name",
          width: "12%",
          slots: {
            customRender: "name"
          }
        },
        {
          title: "治愈",
          key: "curedCount",
          dataIndex: "curedCount",
          width: "7%",
          slots: {
            customRender: "curedCount"
          }
        },
        {
          title: "明显进步",
          key: "significantCount",
          dataIndex: "significantCount",
          width: "9%",
          slots: {
            customRender: "significantCount"
          }
        },
        {
          title: "进步",
          key: "progressCount",
          dataIndex: "progressCount",
          width: "7%",
          slots: {
            customRender: "progressCount"
          }
        },
        {
          title: "使用次数",
          key: "totalCount",
          dataIndex: "totalCount",
          width: "9%",
          sorter: (a, b) => {
            return a.totalCount - b.totalCount
          },

        },
        {
          title: "平均疗效",
          key: "averageScore",
          dataIndex: "averageScore",
          width: "13%",
          slots: {
            customRender: "averageScore"
          },
          sorter: (a, b) => {
            return a.averageScore - b.averageScore
          },
        },
        {
          title: "有效积分",
          key: "totalScore",
          dataIndex: "totalScore",
          width: "10%",
          sorter: (a, b) => {
            return a.totalScore - b.totalScore
          },
          slots: {
            customRender: "totalScore"
          }
        },
        {
          title: "使用比例",
          key: "usedRatio",
          dataIndex: "usedRatio",
          width: "13%",
          slots: {
            customRender: "usedRatio"
          },
          sorter: (a, b) => {
            return this.getNumbers(a.usedRatio) - this.getNumbers(b.usedRatio)
          },
        },
        {
          title: "操作",
          dataIndex: "operation",
          width: "10%",
          slots: {
            customRender: "operation"
          }
        }
      ],
      data: [],
      filteredData: [],
      labelCol: {
        span: 2,
      },
      wrapperCol: {
        span: 14,
      },
    };
  },
  emits: ['update:modelValue'],
  created() {

  },
  mounted() {
  },
  activated() {
  },
  //获取父元素传值
  props: {
    record: {
      type: Object,
      default: {}
    },
    modelValue: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
  },
  methods: {

    async detail() {
      console.log(this.record);
      let res = await Detail({ category: this.record.category, projectId: this.record.projectId });

      this.data = res.data;
      this.filteredData = res.data;
      // if (res.data?.records?.length > 0) {
      // } else {
      //   this.data = []
      // }
    },
    getNumbers(str) {
      if (str) {
        return str.match(/\d+/)[0]
      }
    },
    getInnerStudyDetail(record) {
      this.visible2 = true
      this.selectedRow = { ...this.record, ...record }
    },
    closeModal() {
      this.$emit('update:modelValue', false);
    },
    async onDelete(id) {
      console.log(this.record);
      let res = await Update({ id, standardDiseaseId: 0 });
      this.detail()
    },

    filterData() {
      // 开始时将 filteredData 设为原始 data 的副本
      let tempData = [...this.data];

      // 根据 usedRatio 过滤
      if (this.inputs.usedRatio !== "全部") {
        tempData = tempData.filter(item => item.countValid === (this.inputs.usedRatio == "有效"));
      }

      if (this.inputs.averageScore !== "全部") {
        tempData = tempData.filter(item => item.scoreValid === (this.inputs.averageScore == "有效"));
      }

      // 更新 filteredData
      this.filteredData = tempData;
    },

  },
  watch: {
    record(val) {
      this.detail()
    },
    modelValue(val) {
      this.visible = val
    },
  }
};
</script>

<style scoped>
.tableStyle {
  border: 1px solid #f0f0f0;
  padding: 10px;
}

.thead {
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.5em;
}

.tr {
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
  font-size: 16px;
}

.tr>div {
  width: 12.5%;
  padding: 5px 12px;
}

.selector {
  width: 150px;
  margin-right: 30px;
}
</style>
